@import "src/constants/style";

.note-reaction-chip__root {
  all: unset; // remove default buttons styling

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  // total dimensions: 50x28
  width: 35px;
  height: 18px;
  padding: 5px 8px 5px 7px;
  border-radius: $rounded--full;
  background-color: $gray--300;

  font-size: $text--base;

  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: $gray--500;
  }
}

.note-reaction-chip__reaction {
  font-size: $text--md;
}

.note-reaction-chip__amount {
  font-size: $text--sm;
  font-weight: 600;
  line-height: 18px;
  color: $navy--900;
}

.note-reaction-chip__root--self {
  background-color: var(--accent-color--100);

  &:hover {
    background-color: var(--accent-color--200);
  }
}

// override
.note-reaction-chip__root--override-active {
  background-color: var(--accent-color--light);

  .note-reaction-chip__amount {
    color: $gray--000;
  }

  &:hover {
    background-color: var(--accent-color--light); // essentially no hover color when active.
  }
}

[theme="dark"] {
  .note-reaction-chip__root {
    background-color: $navy--600;

    &:hover {
      background-color: $navy--300;
    }
  }

  .note-reaction-chip__root--self {
    background-color: rgba(var(--accent-color--dark-rgb), 0.6);

    &:hover {
      background-color: rgba(var(--accent-color--dark-rgb), 0.8);
    }
  }

  .note-reaction-chip__root--override-active {
    background-color: rgba(var(--accent-color--dark-rgb), 0.8);
    &:hover {
      background-color: rgba(var(--accent-color--dark-rgb), 0.8);
    }
  }

  .note-reaction-chip__amount {
    color: $gray--000;
  }
}
