@import "src/constants/style";

.note-dialog__note-options {
  all: unset;
  display: flex;
  flex-direction: column;
  height: 100%;
  grid-area: 1 / 2 / 2 / 2;
  align-items: flex-end;
  gap: 8px;
}

.note-dialog__note-option {
  padding-top: 0;
}

.note-option__button {
  height: 32px;
  width: 32px;
  padding: 0;
  border: 0;
  color: var(--accent-color--light);
  background-color: var(--accent-color--100);
  border-radius: $rounded--full;
  cursor: pointer;
  transition:
    background-color 80ms ease-in-out,
    color 80ms ease-in-out;
  box-shadow:
    0 1px 3px 0 rgba(var(--accent-color--light-rgb), 0.1),
    0 1px 2px -1px rgba(var(--accent-color--light-rgb), 0.1);

  > svg {
    height: 100%;
    width: 100%;
  }

  &:hover {
    background-color: var(--accent-color--light);
    color: $gray--000;
  }
  &:focus-visible {
    outline: 2px solid var(--accent-color--light);
  }
}

[theme="dark"] {
  .note-option__button {
    background-color: rgba(var(--accent-color--dark-rgb), 0.6);
    color: var(--accent-color--dark);

    &:hover {
      background-color: var(--accent-color--light);
      color: $gray--000;
    }
    &:focus-visible {
      outline: 2px solid rgba(var(--accent-color--dark-rgb), 0.6);
    }
  }
}
