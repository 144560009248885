@import "constants/style";

.note-dialog__scrollbar {
  display: flex;
  flex-direction: column;

  border-radius: $note__border-radius;

  overflow: hidden;
  @include scrollbar();
}

// inner container preventing scrollbar flowing over border radius
.note-dialog__inner-scrollbar {
  width: 100%;
  height: 100%;
  overflow: hidden scroll;
  box-sizing: border-box;
}

.note-dialog__note-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: $spacing--base;
}

@media #{$smartphone} {
  .note-dialog__scrollbar {
    padding-left: 0;
  }
}

@media screen and (max-width: $breakpoint--desktop) {
  .note-dialog__scrollbar {
    padding-bottom: $spacing--xl;
  }
}
