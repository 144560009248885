@import "src/constants/style";

.column__header-menu-dropdown {
  position: absolute;
  right: 0;
  top: 75%;
  width: 180px;
  background-color: $gray--000;
  border-radius: 8px;
  box-shadow:
    0 4px 6px -1px rgb(0 0 0 / 0.1),
    0 2px 4px -2px rgb(0 0 0 / 0.1);
  z-index: 150;
}

.column__header-menu-dropdown > ul {
  list-style: none;
  padding: $spacing--xs 0;
  margin: 0;
}

.column__header-menu-dropdown > ul > li {
  height: 32px;
  padding: 0 $spacing--sm;
  background-color: $gray--000;

  &:hover {
    filter: $darken--slightly;
  }

  &:focus-within {
    background-color: $gray--200;
  }
}

.column__header-menu-dropdown > ul > li > button {
  cursor: pointer;
  border: 0;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: $text-size--small;
}

.column__header-menu-dropdown > ul > li > button > svg {
  height: $icon--large;
  width: $icon--large;
}

.column__header-menu-dropdown > ul > li:last-of-type {
  height: auto;
  padding: $spacing--xs $spacing--base;
  display: flex;
  justify-content: space-between;
}

.column__header-menu-dropdown > ul > li:last-child > button {
  height: 18px;
  width: 18px;
  border-radius: 4px;
  background-color: var(--accent-color--light);
  transition: all 0.08s ease-out;

  &:hover {
    transform: scale(1.1);
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px $navy--300;
  }
}

// Hacky way to not show delete column button if it's the last column
.column:only-of-type .column__header-menu-dropdown > ul > li:nth-child(5) {
  display: none;
}

[theme="dark"] {
  .column__header-menu-dropdown {
    background-color: $navy--500;
  }

  .column__header-menu-dropdown > ul > li {
    background-color: $navy--500;

    &:hover {
      filter: $brighten--slightly;
    }

    &:focus-within {
      background-color: $navy--400;
    }
  }

  .column__header-menu-dropdown > ul > li > button {
    color: $gray--000;
  }

  .column__header-menu-dropdown > ul > li:last-child > button:focus-visible {
    box-shadow: 0 0 0 2px $gray--000;
  }
}
