@import "constants/style";

.homepage {
  background: $gray--300;
}

.homepage__hero {
  background: $gray--000;
  min-height: 100vh;
}

.homepage__header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: $spacing--base;

  display: flex;
  justify-content: space-between;
}

.homepage__settings {
  display: inline-flex;
  align-items: center;
  gap: 6px;

  list-style-type: none;
  margin: 0;
  padding: 0;
}

.homepage__language {
  background: $gray--600;
  box-shadow: 0 0 rgba($gray--700, 0);

  &:hover {
    box-shadow: 0 2px 12px 6px rgba($gray--700, 0.2);
  }
}

[theme="dark"] {
  .homepage__language {
    background: $navy--500;
    box-shadow: none;
  }
}

.homepage__logout-button {
  margin-left: $spacing--xs;
}

.homepage__hero-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 100vh;
  padding-top: 86px;
  padding-bottom: $spacing--xl;
}

.homepage__hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
  max-width: 1080px;
  padding: 0 $spacing--base;
  margin-top: 48px;
  margin-bottom: 48px;
}

.homepage__hero-content > * {
  flex: 1 1 0;
}

.homepage__hero-title {
  font-size: clamp(36px, 4vw, 64px);
  font-weight: 800;
  line-height: 1.125em;
  letter-spacing: 0.015em;
  margin-bottom: 0.5em;
}

.homepage__hero-title-team {
  color: $blue--500;
  background: linear-gradient(to right, #e95a0c, #fab900);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.homepage__hero-title-retrospective {
  color: $pink--500;
  background: linear-gradient(to right, #004b8a, #6cbd9c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.homepage__hero-text {
  margin-bottom: 2.2em;
  font-size: clamp(16px, 1vw, 18px);
  line-height: 1.375em;
}

.homepage__start-button {
  padding-left: 20px;
}

.homepage__start-button .homepage__proceed-icon {
  flex: 0 0 $icon--medium;
  height: $icon--medium;
  width: $icon--medium;
}

.homepage__proceed-icon {
  margin-left: $spacing--base;
}

.homepage__illustration {
  order: -1;
}

.homepage__footer {
  display: flex;
  flex-direction: column;
  gap: 1em;

  text-align: left;
  max-width: 1280px;
  margin: 0 auto;
  padding: $spacing--xl;
}

.homepage__footer > * {
  flex: 1 1 auto;
}

.homepage__app-info {
  order: 1;
}

.homepage__footer-developers {
  display: flex;
  flex: 2 2 auto;
  gap: 2px;
  color: $gray--800;
  font-size: $text-size--medium;
  line-height: $line-height--small;
  order: -1;
  justify-content: center;
  align-items: center;
}

.homepage__footer-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.homepage__footer-link {
  display: block;
  color: $gray--800;
  font-size: $text-size--medium;
  line-height: $line-height--large;
}

.homepage__footer-link a {
  color: currentColor;
  text-decoration: none;
  transition: all 0.08s ease-out;

  &:focus-visible {
    outline: none;
    text-decoration: underline;
  }
}

[theme="dark"] {
  .homepage {
    background: $navy--600;
  }

  .homepage__hero {
    background: $navy--600;
  }

  .homepage__hero-title {
    color: $gray--000;
  }

  .homepage__hero-text {
    color: $gray--000;
  }

  .homepage__footer-link {
    color: $gray--700;

    a:focus-visible,
    a:hover {
      color: $gray--000;
    }
  }

  .homepage__footer-developers {
    color: $gray--700;
  }
}

@media #{$tablet} {
  .homepage__header {
    padding: $spacing--xl;
  }

  .homepage__hero {
    padding-left: 76px;
    padding-right: 76px;
  }

  .homepage__hero-content {
    flex-direction: row;
    gap: 128px;
    margin-top: 112px;
    margin-bottom: 112px;
  }

  .homepage__illustration {
    order: initial;
  }

  .homepage__hero-content-wrapper {
    padding-top: 0;
  }

  .homepage__footer {
    flex-direction: row;
    text-align: right;
  }

  .homepage__app-info {
    order: initial;
  }

  .homepage__footer-developers {
    text-align: center;
    order: initial;
  }

  .homepage__footer-developers:last-child {
    flex: 1 1 auto;
    justify-content: flex-end;
  }

  .homepage__footer-link {
    display: inline;
  }

  .homepage__footer-link + .homepage__footer-link::before {
    content: "|";
    margin-left: $spacing--xs;
    margin-right: $spacing--xs;
  }
}

.homepage__logout-button-icon {
  height: $icon--medium;
  width: $icon--medium;
  flex: 0 0 $icon--medium;
}
