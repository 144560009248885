@import "src/constants/style";
@import "../Votes.scss";

.vote-button-add {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 32px;
  width: 32px;

  background-color: var(--accent-color--100);
  color: var(--accent-color--light);
  transition:
    background-color 80ms ease-in-out,
    color 80ms ease-in-out;
  box-shadow:
    0 1px 3px 0 rgba(var(--accent-color--light-rgb), 0.1),
    0 1px 2px -1px rgba(var(--accent-color--light-rgb), 0.1);
}
.vote-button-add:disabled {
  opacity: 0.5;
}

.vote-button-add__icon {
  height: $icon--medium;
  width: $icon--medium;
  color: inherit;
  pointer-events: none;
}
.vote-button-add:enabled:hover {
  background-color: var(--accent-color--light);
  color: $gray--000;
}
.vote-button-add:focus-visible {
  outline: 2px solid rgba(var(--accent-color--light-rgb), 0.5);
}

[theme="dark"] {
  .vote-button-add {
    background-color: rgba(var(--accent-color--dark-rgb), 0.6);
    color: var(--accent-color--dark);
    filter: drop-shadow(0 3px 4px rgba($navy--900, 0.16));

    &:enabled:hover {
      background-color: var(--accent-color--dark);
    }
  }
}
