@import "src/constants/style";
@import "../Votes.scss";

.vote-button-remove {
  position: relative;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: var(--accent-color--100);
  color: var(--accent-color--light);
  transition:
    background-color 80ms ease-in-out,
    color 80ms ease-in-out;
  box-shadow:
    0 1px 3px 0 rgba(var(--accent-color--light-rgb), 0.1),
    0 1px 2px -1px rgba(var(--accent-color--light-rgb), 0.1);
}

.vote-button-remove:disabled {
  cursor: default;
}

.vote-button-remove:active {
  transform: none;
}

.vote-button-remove:enabled:hover {
  background-color: var(--accent-color--light);
  color: $gray--000;
  .vote-button-remove__count {
    display: none;
  }
  .vote-button-remove__icon {
    display: block;
  }
}

.vote-button-remove:focus-visible {
  outline: 2px solid rgba(var(--accent-color--light-rgb), 0.5);
  .vote-button-remove__icon {
    display: block;
  }
}

.vote-button-remove__icon {
  height: $icon--medium;
  width: $icon--medium;
  display: none;
}

.bump {
  animation: bump 0.2s linear;
}

@keyframes bump {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  to {
    transform: scale(1);
  }
}

.vote-button-remove__folded-corner {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0;
  border-style: solid;
  background: rgba($gray--700, 0.5);
  box-shadow:
    2px -2px 0px $gray--000,
    0 1px 1px rgba($navy--900, 0.1),
    -1px 1px 1px rgba($navy--900, 0.1);
  border-radius: 0 0 0 16px;
  border-color: $gray--000 $gray--000 transparent transparent;
  transition: border-width 0.15s ease-in-out;
}

.vote-button-remove:enabled:hover > .vote-button-remove__folded-corner {
  border-width: 12px 0 0 12px;
}

[theme="dark"] {
  .vote-button-remove {
    color: $gray--000;
    background-color: rgba(var(--accent-color--dark-rgb), 0.6);
    filter: drop-shadow(0 3px 4px rgba($navy--900, 0.16));

    &:enabled:hover {
      background-color: var(--accent-color--dark);
    }
  }

  .vote-button-remove__folded-corner {
    box-shadow:
      2px -2px 0px $navy--500,
      0 1px 1px rgba($navy--900, 0.1),
      -1px 1px 1px rgba($navy--900, 0.1);
    border-color: $navy--500 $navy--500 transparent transparent;
  }
}
